
import React from 'react';
import WelcomeSlide from '../../Components/CREW/WelcomeSlide';
import InfoSlides from '../../Components/CREW/InfoSlides';


const Crew = () => {

    return (
        <div>
            <WelcomeSlide />

            <InfoSlides />
        </div>
    )
}

export default Crew;



